import * as firestore from '@firebase/firestore';
import { connectStorageEmulator, getStorage } from '@firebase/storage';
import { connectFirestoreEmulator, getFirestore } from '@firebase/firestore';

import { Injectable } from '@angular/core';

import { LOCAL_FIREBASE_SETTINGS } from '@bp/frontend/features/firebase/settings';

import { FirebaseService } from './firebase.service';

/*
 * Utility class to test firebase functions, store and storage locally.
 */
@Injectable({ providedIn: 'root' })
export class LocalFirebaseService extends FirebaseService {

	constructor() {
		super();

		this.init$
			.subscribe(() => {
				connectFirestoreEmulator(getFirestore(), 'localhost', 8080);

				connectStorageEmulator(getStorage(), 'localhost', 9199);
			});
	}

	protected override _buildFirebaseFunctionUrl(firebaseFunctionName: string): string {
		return `${ LOCAL_FIREBASE_SETTINGS.functionsURL }/${ firebaseFunctionName }`;
	}

	protected override _buildFirestoreSettings(): firestore.FirestoreSettings {
		return {
			...super._buildFirestoreSettings(),
			// Due to some interactions with MirageJs, FB is not reachable without this tuning.
			experimentalForceLongPolling: true,
		};
	}

}
