import { map } from 'rxjs';

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { MediaService } from '@bp/frontend/features/media';

import {
	LegalPage, PROMO_TRUST_LOGOS_FOR_DARK_BLUE_BG_LAPTOP, PROMO_TRUST_LOGOS_FOR_DARK_BLUE_BG_MOBILE
} from '@bp/promo/shared/core';

@Component({
	selector: 'bp-footer',
	templateUrl: './footer.component.html',
	styleUrls: [ './footer.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {

	private readonly __mediaService = inject(MediaService);

	protected readonly _trustLogosDefs$ = this.__mediaService.greaterThan$('Tablet').pipe(
		map(isGreater => isGreater ? PROMO_TRUST_LOGOS_FOR_DARK_BLUE_BG_LAPTOP : PROMO_TRUST_LOGOS_FOR_DARK_BLUE_BG_MOBILE),
	);

	protected readonly _legalPages = LegalPage.getList();

}
