<bp-submenu-item itemName="Company" popupClassPrefix="company" [dropdownMode]="dropdownMode">
	<a routerLinkNoOutlets="about-us" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
		<bp-picture
			class="bg-image"
			alt="The BridgerPay Team enjoying a happy hour"
			responsive
			[src]="assetsDir + '/team'"
		/>
		<bp-svg-icon name="logo" />
		<span>About Us</span>
	</a>

	<a routerLinkNoOutlets="newsroom" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
		<bp-picture
			class="bg-image"
			alt="Laptop with BridgerPay's website"
			responsive
			[src]="assetsDir + '/newsroom'"
		/>
		<span class="icon icon-newsroom">
			<bp-svg-icon name="logo" />
		</span>
		<span>Newsroom</span>
	</a>

	<a routerLinkNoOutlets="careers" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
		<bp-picture
			class="bg-image"
			alt="The BridgerPay Sales and Customer Success teams"
			responsive
			[src]="assetsDir + '/careers'"
		/>
		<span class="icon icon-careers"></span>
		<span>Careers</span>
		<bp-we-are-hiring-badge />
	</a>

	<a href="https://developers.bridgerpay.com" bpTargetBlank>
		<bp-picture
			class="bg-image"
			alt="Laptop with code on the screen"
			responsive
			[src]="assetsDir + '/developers'"
		/>
		<span class="icon icon-developers"></span>
		<span>Developers</span>
	</a>
</bp-submenu-item>
