<svg version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink">
	<defs>
		<!-- #region Social media -->
		<symbol id="linkedin" viewBox="0 0 24 24">
			<path
				d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"
			/>
		</symbol>

		<symbol id="linkedin-colored" viewBox="0 0 15 15">
			<path
				d="M12.7685 12.7042H10.6941V9.45564C10.6941 8.68099 10.6803 7.68377 9.61527 7.68377C8.53487 7.68377 8.36955 8.52781 8.36955 9.39926V12.704H6.29525V6.02362H8.28659V6.93657H8.31448C8.51376 6.59582 8.80174 6.31549 9.14774 6.12546C9.49374 5.93542 9.88481 5.84279 10.2793 5.85743C12.3818 5.85743 12.7694 7.24036 12.7694 9.03947L12.7685 12.7042ZM3.95463 5.1105C3.28979 5.11061 2.75073 4.57172 2.75062 3.90689C2.75052 3.24205 3.28935 2.703 3.95419 2.70289C4.61902 2.70272 5.15808 3.24161 5.15819 3.90645C5.15825 4.22572 5.03148 4.53193 4.80577 4.75773C4.58006 4.98354 4.27389 5.11043 3.95463 5.1105ZM4.99183 12.7043H2.91529V6.02362H4.99177V12.7042L4.99183 12.7043ZM13.8026 0.77641H1.87295C1.30912 0.770066 0.846734 1.22173 0.839844 1.78556V13.765C0.846516 14.3291 1.30884 14.7812 1.87289 14.7753H13.8026C14.3679 14.7823 14.832 14.3302 14.8398 13.765V1.78463C14.8318 1.21971 14.3676 0.768097 13.8026 0.77548"
				fill="#0A66C2"
			/>
		</symbol>

		<symbol id="youtube" viewBox="0 0 24 24">
			<path
				d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
			/>
		</symbol>

		<symbol id="twitter" viewBox="0 0 24 24">
			<path
				d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z"
			/>
		</symbol>

		<symbol id="twitter-colored" viewBox="0 0 18 16">
			<path
				d="M18 2.3574C17.326 2.66219 16.611 2.86226 15.879 2.95094C16.6415 2.48444 17.227 1.74571 17.5027 0.865514C16.7778 1.30459 15.9847 1.61395 15.1577 1.78023C14.484 1.04767 13.5243 0.589844 12.4621 0.589844C10.4226 0.589844 8.76909 2.2776 8.76909 4.35922C8.76909 4.6547 8.80179 4.94236 8.86472 5.21831C5.79558 5.06106 3.07448 3.56042 1.25304 1.27984C0.935227 1.83657 0.753117 2.48415 0.753117 3.17487C0.753117 4.48267 1.40513 5.63638 2.39597 6.31238C1.80953 6.29363 1.23599 6.13196 0.723234 5.84085C0.723023 5.85664 0.723023 5.87243 0.723023 5.88829C0.723023 7.71463 1.99596 9.23817 3.68529 9.58446C3.14148 9.73542 2.57107 9.75752 2.01762 9.64905C2.48752 11.1466 3.85137 12.2364 5.46729 12.2669C4.20342 13.2778 2.61105 13.8805 0.880945 13.8805C0.58282 13.8805 0.288914 13.8626 0 13.8278C1.63427 14.8973 3.57539 15.5214 5.66086 15.5214C12.4535 15.5214 16.1679 9.77752 16.1679 4.79638C16.1679 4.63288 16.1644 4.47032 16.1573 4.3087C16.8802 3.77522 17.5042 3.11445 18 2.3574Z"
				fill="#55ACEE"
			/>
		</symbol>

		<symbol id="facebook" viewBox="0 0 24 24">
			<path
				d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
			/>
		</symbol>

		<symbol id="facebook-colored" viewBox="0 0 15 16">
			<path
				d="M15 7.62598C15 3.48387 11.6421 0.125977 7.5 0.125977C3.35789 0.125977 0 3.48381 0 7.62598C0 11.3694 2.74266 14.4722 6.32812 15.0349V9.79395H4.42383V7.62598H6.32812V5.97363C6.32812 4.09395 7.44785 3.05566 9.16096 3.05566C9.98156 3.05566 10.8398 3.20215 10.8398 3.20215V5.04785H9.89414C8.96238 5.04785 8.67188 5.626 8.67188 6.21914V7.62598H10.752L10.4194 9.79395H8.67188V15.0349C12.2573 14.4722 15 11.3695 15 7.62598Z"
				fill="#1877F2"
			/>
			<path
				d="M10.4194 9.79395L10.752 7.62598H8.67188V6.21914C8.67188 5.62594 8.96244 5.04785 9.89414 5.04785H10.8398V3.20215C10.8398 3.20215 9.98156 3.05566 9.16096 3.05566C7.44785 3.05566 6.32812 4.09395 6.32812 5.97363V7.62598H4.42383V9.79395H6.32812V15.0349C6.71579 15.0956 7.1076 15.1261 7.5 15.126C7.8924 15.1261 8.28421 15.0956 8.67188 15.0349V9.79395H10.4194Z"
				fill="white"
			/>
		</symbol>

		<symbol id="facebook-white" viewBox="0 0 11 21">
			<path
				fill="#fff"
				d="M8.64 3.88h1.84V.78A25.5 25.5 0 0 0 7.8.62c-2.65 0-4.47 1.62-4.47 4.6v2.55h-3v3.48h3v8.9h3.6v-8.9H9.9l.45-3.48H6.92V5.56c0-1.02.28-1.68 1.72-1.68Z"
			/>
		</symbol>

		<symbol id="linkedin-white" viewBox="0 0 20 19">
			<path
				fill="#fff"
				d="M4.64 2.59a1.95 1.95 0 1 1-3.9 0 1.95 1.95 0 0 1 3.9 0Zm.06 3.4H.8V18.2h3.9V5.99Zm6.17 0H6.98V18.2h3.85v-6.42c0-3.57 4.66-3.9 4.66 0v6.42h3.85v-7.74c0-6.03-6.89-5.8-8.51-2.84l.04-1.64Z"
			/>
		</symbol>

		<symbol id="youtube-white" viewBox="0 0 25 17">
			<path
				fill="#fff"
				d="m10.07 11.55 6.08-3.43-6.08-3.44v6.87Zm13.55-8.97c.15.54.26 1.26.33 2.18.08.92.11 1.7.11 2.4l.07.96c0 2.5-.18 4.35-.51 5.53a2.71 2.71 0 0 1-2.03 1.98c-.55.15-1.56.25-3.1.32-1.53.08-2.92.11-4.21.11l-1.86.07c-4.91 0-7.97-.18-9.18-.5a2.71 2.71 0 0 1-2.02-1.98 11.6 11.6 0 0 1-.33-2.18c-.08-.91-.12-1.7-.12-2.4L.7 8.13c0-2.51.19-4.36.52-5.54A2.71 2.71 0 0 1 3.24.6C3.8.45 4.8.35 6.34.28 7.88.2 9.28.17 10.56.17L12.42.1c4.9 0 7.96.18 9.17.5 1.06.29 1.74.95 2.03 1.98Z"
			/>
		</symbol>

		<symbol id="instagram-white" viewBox="0 0 20 21">
			<path
				fill="#fff"
				d="M5.73.63h8.2A5.67 5.67 0 0 1 19.6 6.3v8.2a5.66 5.66 0 0 1-5.66 5.66h-8.2A5.67 5.67 0 0 1 .07 14.5V6.3A5.66 5.66 0 0 1 5.73.63Zm-.2 1.96A3.52 3.52 0 0 0 2.02 6.1v8.6a3.51 3.51 0 0 0 3.51 3.5h8.6a3.52 3.52 0 0 0 3.51-3.5V6.1a3.51 3.51 0 0 0-3.51-3.51h-8.6Zm9.43 1.46a1.22 1.22 0 1 1 0 2.44 1.22 1.22 0 0 1 0-2.44ZM9.83 5.52a4.88 4.88 0 1 1 0 9.76 4.88 4.88 0 0 1 0-9.76Zm0 1.95a2.93 2.93 0 1 0 0 5.86 2.93 2.93 0 0 0 0-5.86Z"
			/>
		</symbol>

		<symbol id="share" viewBox="0 0 28 28">
			<path
				d="M19.8 17.4a4.1 4.1 0 0 0-3.2 1.6l-6-3.8c.2-.7.2-1.5 0-2.2l6-3.8a4.1 4.1 0 1 0-.8-1.5l-6 3.8a4.1 4.1 0 1 0 0 5.2l6 3.8a4 4 0 0 0 .6 3.4 4.1 4.1 0 1 0 3.4-6.5Zm0-13.2a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM6.6 16.6a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5ZM19.8 24a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Z"
			/>
		</symbol>

		<symbol id="copy-link" fill="none" viewBox="0 0 23 23">
			<path
				d="M12.736 10.265a4.201 4.201 0 0 0-5.94 0l-2.972 2.97a4.201 4.201 0 1 0 5.942 5.942l1.485-1.485"
				stroke="#2578EA"
				stroke-width="2.164"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M9.766 13.235a4.201 4.201 0 0 0 5.94 0l2.972-2.97a4.202 4.202 0 0 0-5.942-5.942L11.251 5.81"
				stroke="#2578EA"
				stroke-width="2.164"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</symbol>
		<!-- #endregion Social media -->

		<!-- #region Utils -->
		<symbol id="arrow-drop-down" viewBox="0 0 24 24">
			<path d="M0 0h24v24H0V0z" fill="none" />
			<path
				d="M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z"
			/>
		</symbol>

		<symbol id="play" viewBox="0 0 33 32">
			<path
				d="M16.63 2.06a13.92 13.92 0 1 0 0 27.85 13.92 13.92 0 0 0 0-27.85ZM21.1 16.2l-6.78 4.93a.25.25 0 0 1-.4-.2v-9.87a.25.25 0 0 1 .4-.2l6.78 4.94a.25.25 0 0 1 0 .4Z"
			/>
		</symbol>

		<symbol id="pdf" viewBox="0 0 33 32">
			<path
				d="M11.284 19.536c-.244 0-.408.023-.493.047v1.562c.1.024.227.03.4.03.636 0 1.027-.32 1.027-.862 0-.486-.337-.777-.934-.777Zm4.624.016c-.265 0-.438.023-.54.047v3.461c.102.024.267.024.415.024 1.084.008 1.789-.589 1.789-1.851.008-1.1-.635-1.681-1.664-1.681Z"
			/>
			<path
				d="M18.886 2.722H8.278a2.652 2.652 0 0 0-2.652 2.652V26.59a2.652 2.652 0 0 0 2.652 2.652h15.911a2.652 2.652 0 0 0 2.652-2.652V10.678l-7.955-7.956Zm-5.97 18.816c-.41.384-1.014.556-1.718.556a2.965 2.965 0 0 1-.409-.023v1.89H9.604v-5.219a9.93 9.93 0 0 1 1.616-.108c.739 0 1.264.14 1.618.423.337.268.565.706.565 1.224-.002.52-.174.958-.487 1.257Zm5.048 1.796c-.557.463-1.404.683-2.44.683-.62 0-1.06-.04-1.357-.08v-5.193a10.531 10.531 0 0 1 1.616-.11c1.004 0 1.656.18 2.165.564.55.409.895 1.06.895 1.995 0 1.011-.37 1.71-.879 2.141Zm4.9-3.68h-2.032v1.209h1.899v.973h-1.899v2.127h-1.201v-5.29h3.232v.982Zm-3.978-7.65H17.56v-6.63l6.63 6.63h-5.304Z"
			/>
		</symbol>

		<symbol id="on-demand-info" viewBox="0 0 16 16">
			<circle cx="7.77" cy="8.16" r="7.29" fill="#9D9DD3" />
			<path
				fill="#C7C7F9"
				d="M8.53 5.3c0 .43-.34.79-.77.79A.8.8 0 0 1 7 5.3c0-.39.36-.75.76-.75.41 0 .77.35.77.75ZM7.08 7.36c0-.46.29-.76.68-.76.4 0 .69.3.69.76V11c0 .45-.29.76-.69.76s-.68-.3-.68-.76V7.36Z"
			/>
		</symbol>
		<!-- #endregion Utils -->
	</defs>
</svg>
