<h2 class="headliner uppercase">
	OPERATE ALL YOUR&nbsp;<bp-lottie-scene sceneName="home-atf-sliding-items" looped />&nbsp;PAYMENTS ON ONE PLATFORM
</h2>

<p>Centralized dashboard and reports to make better and faster payment processing decisions.</p>

<a mat-flat-button class="promo-flat-button" routerLink="/product" color="primary"> Learn more </a>

<div class="animation">
	<bp-lottie-scene sceneName="home-operations-tooltip" looped />
	<bp-lottie-scene *bpIfGreaterThan="'PhoneLg'; else phoneTpl" sceneName="home-operations-stripes" eager looped />

	<ng-template #phoneTpl>
		<bp-lottie-scene sceneName="home-operations-stripes-phone" class="home-operations-stripes" looped />
	</ng-template>
</div>
