import { Injectable } from '@angular/core';

import { Dictionary } from '@bp/shared/typings';

import { UtmService } from '@bp/frontend/features/analytics/utms';
import { EnvironmentService } from '@bp/frontend/services/environment';
import { SessionStorageService } from '@bp/frontend/services/storage';

const wizardConfigIdParamName = 'configId';
const appInitUrl = new URL(window.location.toString());

@Injectable({ providedIn: 'root' })
export class WizardConnectService {

	wizardConfigId: string | null = this._getWizardConfigId();

	constructor(
		private readonly _environment: EnvironmentService,
		private readonly _sessionStorageService: SessionStorageService,
		private readonly _utmService: UtmService,
	) {
		appInitUrl.searchParams.delete(wizardConfigIdParamName);
	}

	getWizardUrl(params?: Dictionary<string>): string {
		const signupUrlWithQueryParams = new URL(this._environment.signupOrigin!);

		const signupUrlQueryParams = new URLSearchParams([
			...this._utmService.getUtmSearchParamsEntries(),
			...Object.entries({ ...params }),
		]);

		if (this.wizardConfigId)
			signupUrlQueryParams.append('id', this.wizardConfigId);

		signupUrlWithQueryParams.search = signupUrlQueryParams.toString();

		return signupUrlWithQueryParams.toString();
	}

	private _getWizardConfigId(): string | null {
		const queryParamWizardConfigId = appInitUrl.searchParams.get(wizardConfigIdParamName);

		if (queryParamWizardConfigId)
			this._sessionStorageService.set(wizardConfigIdParamName, queryParamWizardConfigId);

		return this._sessionStorageService.get<string | null>(wizardConfigIdParamName);
	}

}
