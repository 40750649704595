import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'bp-payment-platform-to-fit',
	templateUrl: './payment-platform-to-fit.component.html',
	styleUrls: [ './payment-platform-to-fit.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentPlatformToFitComponent {

	assetsDir = '/assets/home/above-fold';

}
