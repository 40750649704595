import { mergeMap, tap, defer, filter, exhaustMap, map, distinctUntilChanged } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { isEqual } from '@bp/shared/utilities/core';

import { CrmLeadsApiService, IntercomContactApiService } from '@bp/frontend/domains/crm/leads/services';
import { GoogleTagService } from '@bp/frontend/features/analytics';
import { CrmLead, IntercomWebhookNotificationTopic, WriteCrmLeadApiRequestResult } from '@bp/frontend/domains/crm/leads/models';
import { CurrentCrmUserFacade } from '@bp/frontend/domains/crm/users/+current-crm-user-state';
import { GtagEvents } from '@bp/frontend/features/analytics/models';
import { BpError } from '@bp/frontend/models/core';
import { filterPresent } from '@bp/frontend/rxjs';
import { CrmUserAnalytics } from '@bp/frontend/domains/crm/users/models';
import { flushLastSourceValueOnTimeoutOrAnalyticsEventChange } from '@bp/frontend/features/firebase/utils';
import { EnvironmentService } from '@bp/frontend/services/environment';
import { apiResultWithRequest, apiVoidResult } from '@bp/frontend/models/common';

import {
	saveCrmLeadKeptInStore, saveCrmLeadAnalytics, convertCrmLeadToCrmUser, trySaveCrmLeadAnalytics, trySaveCrmLeadKeptInStore
} from './current-crm-lead.actions';
import {
	convertCrmLeadToCrmUserFailure, convertCrmLeadToCrmUserSuccess, saveCrmLeadFailure, saveCrmLeadSuccess, saveCrmLeadAnalyticsSuccess, saveCrmLeadAnalyticsFailure, intercomContactGotCreatedOrUpdated
} from './current-crm-lead-api.actions';
import { CurrentCrmLeadFacade } from './current-crm-lead.facade';
import { IState } from './current-crm-lead.feature';

@Injectable()
export class CurrentCrmLeadEffects {

	private readonly __store$ = inject<Store<IState>>(Store);

	private readonly __actions$ = inject<Actions>(Actions);

	private readonly __environment = inject(EnvironmentService);

	private readonly __currentCrmLeadFacade = inject(CurrentCrmLeadFacade);

	private readonly __currentCrmUserFacade = inject(CurrentCrmUserFacade);

	private readonly __crmLeadsApiService = inject(CrmLeadsApiService);

	private readonly __googleTagService = inject(GoogleTagService);

	private readonly __intercomContactApiService = inject(IntercomContactApiService);

	trySaveCrmLeadKeptInStore$ = createEffect(() => this.__actions$.pipe(
		ofType(trySaveCrmLeadKeptInStore, trySaveCrmLeadAnalytics),
		filter(({ lead }) => !!lead.canBeSaved()),
		distinctUntilChanged(isEqual),
		// we want to lessen writes to firebase, since each lead instance is an accumulation of related data
		// we flush it on timeout or on analytics event change
		flushLastSourceValueOnTimeoutOrAnalyticsEventChange(({ lead }) => lead.analytics?.lastEventName),
		map(({ type, lead }) => type === trySaveCrmLeadKeptInStore.type
			? saveCrmLeadKeptInStore({ lead })
			: saveCrmLeadAnalytics({ lead })),
	));

	saveCrmLead$ = createEffect(() => this.__actions$.pipe(
		ofType(saveCrmLeadKeptInStore, saveCrmLeadAnalytics),
		filter(() => this.__environment.isDeployedStagingOrProduction),
		mergeMap(({ type, lead }) => defer(
			() => this.__crmLeadsApiService.write(lead),
		)
			.pipe(apiResultWithRequest({
				request: lead,
				successAction: type === saveCrmLeadKeptInStore.type ? saveCrmLeadSuccess : saveCrmLeadAnalyticsSuccess,
				failureAction: type === saveCrmLeadKeptInStore.type ? saveCrmLeadFailure : saveCrmLeadAnalyticsFailure,
			}))),
	));

	onSaveSuccessDispatchAnalyticsEvent$ = createEffect(
		() => this.__actions$.pipe(
			ofType(saveCrmLeadSuccess, saveCrmLeadAnalyticsSuccess),
			filter(({ type, result }) => type === saveCrmLeadAnalyticsSuccess.type
				? result === WriteCrmLeadApiRequestResult.newRecordWasCreated
				: true),
			tap(({ result }) => void this.__dispatchAnalyticsCrmLeadEvent(result, this.__currentCrmLeadFacade.lead!)),
		),
		{ dispatch: false },
	);

	onUsersAnalyticsUpdateSaveCrmLeadWithAnalytics = this.__currentCrmUserFacade.analytics$
		.subscribe(analytics => void this.__store$.dispatch(trySaveCrmLeadAnalytics({
			lead: this.__currentCrmLeadFacade.factory({
				...this.__currentCrmLeadFacade.lead!,
				analytics,
			}),
		})));

	convertCrmLeadToUser$ = createEffect(() => this.__actions$.pipe(
		ofType(convertCrmLeadToCrmUser),
		filter(() => this.__environment.isDeployedStagingOrProduction),
		exhaustMap(() => defer(() => this.__crmLeadsApiService
			.write(this.__currentCrmLeadFacade.lead!)
			.pipe(apiVoidResult(convertCrmLeadToCrmUserSuccess, convertCrmLeadToCrmUserFailure)))),
	));

	listenForIntercomContactChanges$ = createEffect(() => this.__intercomContactApiService
		.listenToRemoteChanges()
		.pipe(
			filterPresent,
			map(intercomContact => intercomContactGotCreatedOrUpdated({ intercomContact })),
		));

	onIntercomContactGotCreatedOrUpdatedCreateLead$ = createEffect(
		() => this.__actions$.pipe(
			ofType(intercomContactGotCreatedOrUpdated),
			tap(({ intercomContact }) => void this.__currentCrmLeadFacade.updateAndSaveLeadKeptInStore({
				email: intercomContact.email,
				fullName: intercomContact.fullName,
				phone: intercomContact.phone,
				isIntercomLead: true,
				intercomCrmOwnerEmail: intercomContact.ownerEmail,
				analytics: intercomContact.lastIntercomWebhookNotificationTopic === IntercomWebhookNotificationTopic.OwnerAssigned
					? CrmUserAnalytics.buildAnalyticsEventDTO('intercom_lead_owner_assigned')
					: undefined,
			})),
		),
		{ dispatch: false },
	);

	private __dispatchAnalyticsCrmLeadEvent(writeCrmLeadResult: WriteCrmLeadApiRequestResult, crmLead: CrmLead): void {
		if (writeCrmLeadResult === WriteCrmLeadApiRequestResult.alreadyConverted)
			return;

		this.__googleTagService.setGlobalVariables({ userEmail: crmLead.email });

		this.__googleTagService.dispatchEvent(
			writeCrmLeadResult === WriteCrmLeadApiRequestResult.newRecordWasCreated
				? this.__getNewCrmLeadEvent(crmLead)
				: this.__getUpdatedCrmLeadEvent(crmLead),
		);
	}

	private __getNewCrmLeadEvent(crmLead: CrmLead): GtagEvents.List {
		switch (true) {
			case crmLead.isCustomer:
				return 'generate_lead';

			case crmLead.isPartner:
				return 'new_psp_partner';

			default:
				throw new BpError(`Unknown crm lead type: ${ crmLead.type }`);

		}
	}

	private __getUpdatedCrmLeadEvent(crmLead: CrmLead): GtagEvents.List {
		switch (true) {
			case crmLead.isCustomer:
				return 'lead_updated';

			case crmLead.isPartner:
				return 'psp_partner_updated';

			default:
				throw new BpError(`Unknown crm lead type: ${ crmLead.type }`);

		}
	}
}
