import { BehaviorSubject } from 'rxjs';

import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

import { ModalService } from '@bp/frontend/features/modal';
import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { EnvironmentService } from '@bp/frontend/services/environment';

import { MOBILE_MENU_DIALOG_ID } from '../modal-menu';

@Component({
	selector: 'bp-header',
	templateUrl: './header.component.html',
	styleUrls: [ './header.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'[class.on-top]': 'mobileMenuDialog$.value',
	},
})
export class HeaderComponent extends Destroyable {

	mobileMenuDialog$ = new BehaviorSubject<MatDialogRef<any> | undefined>(undefined);

	constructor(
		public environment: EnvironmentService,
		private readonly _modalService: ModalService,
		private readonly _router: Router,
	) {
		super();

		this._modalService
			.getOpenModalById$(MOBILE_MENU_DIALOG_ID)
			.pipe(takeUntilDestroyed(this))
			.subscribe(this.mobileMenuDialog$);
	}

	openMobileMenu(): void {
		void this._router.navigate([{ outlets: { modal: [ 'menu' ]} }]);
	}

}
