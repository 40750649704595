<div class="info-block devs">
	<img loading="lazy" alt="Developers icon" src="{{ _assetsDir }}/for-devs.svg" />
	<h4>For Developers</h4>

	<p>Build your own payment stack with the BridgerPay API.</p>

	<a class="link nowrap" href="https://developers.bridgerpay.com" bpTargetBlank> Access our documentation </a>
</div>

<div class="info-block pricing">
	<img loading="lazy" alt="Stacked coins icon" src="{{ _assetsDir }}/pricing.svg" />
	<h4>For Payment Providers</h4>

	<p>
		Interested in connecting your payment gateway or method into BridgerPay?

		<button bpShowRegisterPspSurveyOnClick class="link nowrap">Register here</button>
	</p>
</div>
