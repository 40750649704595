import { combineLatest } from 'rxjs';

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { SLIDE } from '@bp/frontend/animations';
import { ModalHostComponent } from '@bp/frontend/features/modal';
import { takeUntilDestroyed } from '@bp/frontend/models/common';
import { takeFirstPresent, takeFirstTruthy } from '@bp/frontend/rxjs';
import { MediaService } from '@bp/frontend/features/media';

export const MOBILE_MENU_DIALOG_ID = 'mobile-popup-menu';

@Component({
	selector: 'bp-modal-menu',
	templateUrl: './modal-menu.component.html',
	styleUrls: [ './modal-menu.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE ],
})
export class ModalMenuComponent extends ModalHostComponent {

	private readonly _mediaService = inject(MediaService);

	override id = MOBILE_MENU_DIALOG_ID;

	override panelClass = [ 'modal-menu-pane' ];

	constructor() {
		super();

		this.__closeModalMenuOnLaptopBreakpoint();
	}

	private __closeModalMenuOnLaptopBreakpoint(): void {
		combineLatest([
			this._mediaService.greaterThan$('Tablet')
				.pipe(takeFirstTruthy),
			this._modalService
				.getOpenModalById$(MOBILE_MENU_DIALOG_ID)
				.pipe(takeFirstPresent),
		])
			.pipe(takeUntilDestroyed(this))
			.subscribe(([ , modal ]) => void modal.close());
	}
}
