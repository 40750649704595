import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';

import { ModalComponent, ModalHostComponent, SharedFeaturesModalModule } from '@bp/frontend/features/modal';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';

import { GetStartedFormFlowComponent, SharedModule } from '@bp/promo/shared/core';

@Component({
	selector: 'bp-get-started-modal',
	standalone: true,
	templateUrl: './get-started-modal.component.html',
	styleUrls: [ './get-started-modal.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		GetStartedFormFlowComponent,
		SharedComponentsCoreModule,
		SharedFeaturesModalModule,
		SharedModule,
	],
})
export class GetStartedModalComponent extends ModalHostComponent {

	@ViewChild(ModalComponent, { static: true }) override modal!: ModalComponent;

	override panelClass = [ 'get-started-modal' ];

	protected readonly _assetsDir = '/assets/shared/get-started-modal';

}
