import { map, Observable, startWith } from 'rxjs';
import { takeRight } from 'lodash-es';

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MediaService } from '@bp/frontend/features/media';
import {
	BRIDGER_STORIES_PROMO_ROUTE_PATH, NEWSROOM_PROMO_ROUTE_PATH
} from '@bp/frontend/domains/newsroom/models';

import { TestimonialDto, TESTIMONIALS } from '@bp/promo/shared/core';

import { SEMI_FADE } from './animations';

@Component({
	selector: 'bp-testimonials',
	templateUrl: './testimonials.component.html',
	styleUrls: [ './testimonials.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SEMI_FADE ],
})
export class TestimonialsComponent {

	// eslint-disable-next-line @typescript-eslint/naming-convention
	NEWSROOM_PROMO_ROUTE_PATH = NEWSROOM_PROMO_ROUTE_PATH;

	// eslint-disable-next-line @typescript-eslint/naming-convention
	BRIDGER_STORIES_PROMO_ROUTE_PATH = BRIDGER_STORIES_PROMO_ROUTE_PATH;

	assetsDir = '/assets/home/testimonials/';

	testimonials = [
		TESTIMONIALS[2],
		TESTIMONIALS[5],
		TESTIMONIALS[3],
		TESTIMONIALS[0],
		TESTIMONIALS[1],
	];

	leftTextTestimonials$: Observable<TestimonialDto[]> = this.mediaService.currentBreakpointChange$.pipe(
		startWith(null),
		map(() => {
			if (this.mediaService.greaterThan('Tablet'))
				return this.testimonials.slice(0, 4);

			if (this.mediaService.greaterThan('PhoneLg'))
				return this.testimonials.slice(0, 3);

			return this.testimonials.slice(0, 1);
		}),
	);

	rightTextTestimonials$: Observable<TestimonialDto[]> = this.mediaService.currentBreakpointChange$.pipe(
		startWith(null),
		map(() => {
			if (this.mediaService.greaterThan('Tablet'))
				return takeRight(this.testimonials, 1);

			if (this.mediaService.greaterThan('PhoneLg'))
				return takeRight(this.testimonials, 2);

			return takeRight(this.testimonials, 4);
		}),
	);

	zaloraPreviewThumbnail$: Observable<string> = this.mediaService.currentBreakpointChange$.pipe(
		startWith(null),
		map(() => this.mediaService.greaterThan('Tablet') ? 'laptop' : 'tablet'),
		map(suffix => `${ this.assetsDir }zalora-preview-${ suffix }.png`),
	);

	constructor(public readonly mediaService: MediaService) {
	}

}
