import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'bp-companies',
	templateUrl: './companies.component.html',
	styleUrls: [ './companies.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompaniesComponent {

	protected readonly _companiesTiles = [
		'brown-circled',
		'one-two-trip',
		'james-allen-short',
		'boxxport',
		'blue-nile',
		'sportsevents',
		'zalora',
		'paybis',
		'btobet',
		'fattal',
		'tus-airways',
		'equiti',
		'luxury-escapes-two-lines',
		'gfg',
		'calypsa',
		'qoneco',
		'leonardo',
		'nyx',
	];

}
