<div class="wrapper">
	<div class="text">
		<h2 class="headliner uppercase">SET UP IN SECONDS!</h2>

		<p>
			Connect your business to a PCI DSS Level 1 payment operations platform instantly with hundreds of ready-made
			connections, or build your own in seconds.
		</p>
	</div>

	<bp-call-to-action-button classList="promo-flat-button" bpGoogleTagOnClick="promo_set-up-in-seconds_cta_click" />
</div>

<div class="media">
	<bp-picture src="/assets/home/plugin-payments/girl-using-bp" eager responsive />

	<bp-lottie-scene sceneName="home-plugin-wizard" looped [animationStrategy]="animationStrategy" />
</div>
