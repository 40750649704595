<h1 class="headliner uppercase">
	A PAYMENT OPERATIONS
	<span class="build"> PLATFORM<bp-lottie-scene sceneName="home-check" noFadeIn eager /> </span>
	<br />
	BUILT TO SCALE&nbsp;<bp-lottie-scene sceneName="home-vertical-cards" looped noFadeIn eager />&nbsp;ANY BUSINESS
</h1>

<p>Easily build, manage, and optimize your connections to any payment gateway or method worldwide.</p>

<bp-call-to-action-button
	classList="promo-flat-button"
	bpGoogleTagOnClick="promo_payment-operations-platform_cta_click"
/>

<bp-above-fold-secure [assetsDir]="assetsDir" />

<bp-above-fold-video
	bpOpenYoutubePlayerDialogOnClick="IbwBjaacHU4"
	bpOpenYoutubePlayerDialogOnClickLooped
	bpGoogleTagOnClick="promo_best-solution-video_click"
	[assetsDir]="assetsDir"
/>

<div class="media">
	<bp-lottie-scene sceneName="home-router" looped noFadeIn eager [animationStrategy]="routerAnimationStrategy" />
</div>
