import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { currentCrmLeadFeature } from './current-crm-lead.feature';
import { CurrentCrmLeadEffects } from './current-crm-lead.effects';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(currentCrmLeadFeature),
		EffectsModule.forFeature([ CurrentCrmLeadEffects ]),
	],
})
export class SharedDomainsCrmLeadsCurrentCrmLeadStateModule {

}
