import { BehaviorSubject } from 'rxjs';
import { isScullyRunning } from '@scullyio/ng-lib';

import { ChangeDetectionStrategy, Component, inject, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { FADE_ROUTE_HIT_ANIMATIONS } from '@bp/promo/shared/core';

import { PrimaryRouteAnimationObserverService } from '../../services';

@Component({
	selector: 'bp-root',
	templateUrl: './root.component.html',
	styleUrls: [ './root.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_ROUTE_HIT_ANIMATIONS ],
})
export class RootComponent {

	protected readonly _routeAnimationObserver = inject(PrimaryRouteAnimationObserverService);

	@ViewChild('outlet', { static: true, read: RouterOutlet })
	protected _outlet!: RouterOutlet;

	protected _isNotScullyRunning = !isScullyRunning();

	protected readonly _isLanding$ = new BehaviorSubject(false);

	protected _shouldAnimateOutlet = false;

	onActivate(): void {
		this._shouldAnimateOutlet = true;

		/**
		 * Outlet animation usually starts after all router events are triggered.
		 * So make animation pending a bit before (on ActivationEnd event), so it is becomes usable
		 * on router events subscriptions.
		 */
		this._routeAnimationObserver.started();

		this._isLanding$.next(!!this._outlet.activatedRoute.snapshot.data['landing']);
	}
}
