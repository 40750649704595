<div class="grid">
	<div class="left">
		<a class="headline-testimonial" [routerLink]="[BRIDGER_STORIES_PROMO_ROUTE_PATH, 'XHH47TRoR56ytLX3ysOm']">
			<bp-picture alt="Passportcard testimonial" src="{{ assetsDir }}passportcard" />

			<span class="header">
				PassportCard choose BridgerPay to operate their payments.

				<span class="nowrap">Read more</span>
			</span>
		</a>

		<ng-container
			*ngFor="let testimonial of leftTextTestimonials$ | async"
			[ngTemplateOutlet]="testimonialTpl"
			[ngTemplateOutletContext]="{ testimonial }"
		/>
	</div>

	<div class="right">
		<ng-container
			*ngFor="let testimonial of rightTextTestimonials$ | async"
			[ngTemplateOutlet]="testimonialTpl"
			[ngTemplateOutletContext]="{ testimonial }"
		/>

		<div class="info-block">
			<div class="info-block-text">
				<div class="update mb-4">
					<bp-picture class="mr-2" alt="Rock emoji" svg src="{{ assetsDir }}rock-sign" />

					<span>New Product</span>
				</div>

				<div class="title mb-2">
					Introducing PayWith™. Let your users pay with any mix of methods they want. Its a new way to pay!
				</div>

				<a class="link" [routerLink]="[NEWSROOM_PROMO_ROUTE_PATH, 'o2VzmlQpTo6PNBM1WR5C']"> Read more </a>
			</div>

			<bp-picture alt="Customer with phone" eager src="{{ assetsDir }}paywith" />
		</div>
	</div>
</div>

<ng-template #testimonialTpl let-testimonial="testimonial">
	<div class="testimonial">
		<img
			class="logo mb-1 {{ testimonial.logoName }}"
			srcset="{{testimonial.logo}} 1x, {{testimonial.logo2x}} 2x"
			alt="{{ testimonial.alt }}"
		/>
		<p class="mb-2">“{{ testimonial.shortText ?? testimonial.text }}”</p>
		<p class="mb-0">
			<strong>
				<span class="mr-1">{{ testimonial.name }}</span>
				|
				<span class="ml-1">{{ testimonial.position }}</span>
			</strong>
		</p>
	</div>
</ng-template>
