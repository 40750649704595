<bp-above-fold />

<bp-companies />

<div class="dotted-bg-wrapper">
	<div class="dotted-bg"></div>

	<bp-payment-platform-to-fit />

	<bp-plugin-payments />

	<bp-build-payment-flows />
</div>

<bp-codeless-connections />

<bp-support />

<bp-next-gen-operations />

<bp-testimonials />

<bp-work-smarter
	[headliner]="(mediaService.lessThan$('Tablet') | async) ? 'PAYMENT OPERATIONS' : 'SCALE THROUGH PAYMENTS'"
/>

<!--<bp-contacts-with-concerned-parties></bp-contacts-with-concerned-parties>-->

<bp-for-devs-pricing />
