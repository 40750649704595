import { isScullyRunning } from '@scullyio/ng-lib';

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FreezeAtFractionAnimationStrategy, PlayWhenPartiallyInViewportAnimationStrategy } from '@bp/promo/shared/core';

@Component({
	selector: 'bp-above-fold',
	templateUrl: './above-fold.component.html',
	styleUrls: [ './above-fold.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboveFoldComponent {

	assetsDir = '/assets/home/above-fold/';

	routerAnimationStrategy = isScullyRunning()
		? new FreezeAtFractionAnimationStrategy(0)
		: new PlayWhenPartiallyInViewportAnimationStrategy(0.6);

}
