import { DTO, MapFromDTO } from '@bp/shared/models/metadata';

import { FirebaseDocument } from '@bp/frontend/features/firebase/models';

import { IntercomWebhookNotificationTopic } from './intercom-webhook-notification-topic.enum';

export type IntercomContactDTO = DTO<IntercomContact>;

export class IntercomContact extends FirebaseDocument {

	@MapFromDTO()
	email!: string;

	@MapFromDTO()
	phone?: string;

	@MapFromDTO()
	fullName?: string;

	@MapFromDTO()
	ownerEmail?: string;

	@MapFromDTO()
	lastIntercomWebhookNotificationTopic?: IntercomWebhookNotificationTopic;

	constructor(dto?: IntercomContactDTO) {
		super(dto);
	}

}
