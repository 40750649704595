import { skip } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { Platform } from '@bp/shared/typings';

import { MetatagsService } from '@bp/frontend/features/metatags';
import { AnalyticsService, GoogleTagService, PardotService } from '@bp/frontend/features/analytics';
import { RouteMetatags } from '@bp/frontend/features/metatags/models';
import { UtmService } from '@bp/frontend/features/analytics/utms';
import { MomentTimezoneService } from '@bp/frontend/features/moment/services';
import { SharedStartupService, ProgressiveWebAppService, CloudflareAccessService } from '@bp/frontend/services/core';
import { EnvironmentService } from '@bp/frontend/services/environment';
import { IntercomService } from '@bp/frontend/services/intercom';
import { CookiesUsageConsentService } from '@bp/frontend/features/analytics/cookies-usage-consent';
import { filterPresent, takeFirstPresent } from '@bp/frontend/rxjs';

import { ScullyService, WizardConnectService } from '@bp/promo/shared/services';

@Injectable({
	providedIn: 'root',
})
export class AppStartupService {

	private readonly __sharedStartupService = inject(SharedStartupService);

	private readonly __utmService = inject(UtmService);

	private readonly __metatagsService = inject(MetatagsService);

	private readonly __environment = inject(EnvironmentService);

	private readonly __cookiesUsageConsentService = inject(CookiesUsageConsentService);

	private readonly __momentTimezoneService = inject(MomentTimezoneService);

	private readonly __intercomService = inject(IntercomService);

	private readonly __pwaService = inject(ProgressiveWebAppService);

	private readonly __googleTagService = inject(GoogleTagService);

	private readonly __wizardConnectService = inject(WizardConnectService);

	private readonly __scullyService = inject(ScullyService);

	private readonly __analyticsService = inject(AnalyticsService);

	private readonly __pardotService = inject(PardotService);

	private readonly __cloudflareAccessService = inject(CloudflareAccessService);

	readonly platform: Platform = 'promo';

	init(): void {
		this.__sharedStartupService.init();

		this.__momentTimezoneService.setGuessedTimezone();

		this.__utmService.init();

		this.__pardotService.init();

		this.__intercomService.init();

		this.__tryLockInPortraitOrientation();

		this.__setDefaultMetatagsForAllRoutes();

		this.__initAnalyticsKindServicesOnCookiesUsageConsent();

		this.__pwaService.whenNewVersionAvailableReloadApp();

		if (this.__environment.isNotProduction && !this.__environment.isLocal)
			this.__cloudflareAccessService.whenUserUnauthorizedByCloudflareRedirectToCloudflareLoginPage();

		this.__scullyService.init();

		this.__setAnalyticsGlobalParams();
	}

	private __setDefaultMetatagsForAllRoutes(): void {
		this.__metatagsService.init(
			this.__environment.isProduction ? 'bridgerpay.com' : 'bridgerpay.dev',
			new RouteMetatags({
				// eslint-disable-next-line @typescript-eslint/naming-convention
				site_name: 'BridgerPay | Payment Operations Platform',
				description: 'BridgerPay is the world’s first payment operations platform, built to automate ALL payment flows with a Lego-like interface, empowering ANY business to scale their payments, insights, and revenue with a codeless, unified, and agnostic software.',
				image: '/assets/default-meta-img.png',
				twitter: {
					site: '@bridgerpay',
				},
			}),
		);
	}

	private __tryLockInPortraitOrientation(): void {
		try {
			// @ts-expect-error - no types for this
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-empty-function
			window.screen.orientation.lock('portrait').catch(() => {});
		} catch {

		}
	}

	private __initAnalyticsKindServicesOnCookiesUsageConsent(): void {
		this.__cookiesUsageConsentService.consent$
			.pipe(takeFirstPresent)
			.subscribe(consent => void this.__analyticsService.init(consent));

		this.__cookiesUsageConsentService.consent$
			.pipe(
				filterPresent,
				skip(1), // skip the first emission, because it's handled by init
			)
			.subscribe(consent => void this.__analyticsService.updateConsent(consent));
	}

	private __setAnalyticsGlobalParams(): void {
		this.__googleTagService.setGlobalVariables({
			wizardSetupId: this.__wizardConnectService.wizardConfigId ?? undefined,
		});
	}

}
