import { inject, ModuleWithProviders, NgModule } from '@angular/core';

import { ApiMockPluginsManagerService } from '@bp/frontend/services/core';
import { FirebaseService, LocalFirebaseService } from '@bp/frontend/features/firebase';
import { LocalFirebaseState, MockedBackendState } from '@bp/frontend/services/persistent-state-keepers';

@NgModule({
	imports: [],
	declarations: [],
	providers: [],
})
export class FirebaseApiMockModule {
	static forRoot(): ModuleWithProviders<FirebaseApiMockModule> {
		return {
			ngModule: FirebaseApiMockModule,
			providers: [
				{
					provide: FirebaseService,
					useClass: LocalFirebaseState.isActive ? LocalFirebaseService : FirebaseService,
				},
			],
		};
	}

	private readonly __apiMocksManagerService = inject(ApiMockPluginsManagerService);

	constructor() {
		if (MockedBackendState.isActive && !LocalFirebaseState.isActive) {
			void this.__apiMocksManagerService.register(
				FirebaseApiMockModule,
				async () => import('./plugins/firebase-api-mock-plugin'),
			);
		}
	}
}
