import type { AnimationItem } from 'lottie-web-light';

import { Directive } from '@angular/core';

import type { ILottieAnimationStrategy } from './lottie-animation-strategy';
import { BaseAnimationStrategy } from './base-animation-strategy';

@Directive() // To satisfy compiler
export class FreezeAtFractionAnimationStrategy extends BaseAnimationStrategy implements ILottieAnimationStrategy {

	constructor(private readonly _fraction: number) {
		super();
	}

	override apply($host: HTMLElement, animationItem: AnimationItem): void {
		super.apply($host, animationItem);

		animationItem.goToAndStop(animationItem.totalFrames * this._fraction);
	}

}
