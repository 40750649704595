import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class PrimaryRouteAnimationObserverService {

	private readonly _isAnimationDone$ = new BehaviorSubject(true);

	readonly isAnimationDone$ = this._isAnimationDone$
		.pipe(distinctUntilChanged());

	started(): void {
		this._isAnimationDone$.next(false);
	}

	done(): void {
		this._isAnimationDone$.next(true);
	}

}
