<h2 class="headliner uppercase">
	A TECHNOLOGY THAT <span class="save">SAVES <bp-svg-icon name="check-yes" /></span> DECLINED PAYMENTS
</h2>

<p>
	Bridger Retry™ is a provider-agnostic technology that retries declined card payments seamlessly, increases your
	revenue, and retains more customers!
</p>

<bp-call-to-action-button
	classList="promo-flat-button"
	bpGoogleTagOnClick="promo_technology-that-saves-declined-payments_cta_click"
/>

<div class="animation-wrapper">
	<bp-lottie-scene sceneName="home-checkout" looped [animationStrategy]="_animationStrategy" />
	<canvas #confettiCanvas class="confettiCanvas"></canvas>
</div>
