<bp-modal>
	<ng-template>
		<div class="container">
			<div class="left-block">
				<bp-picture responsive skipPhone src="{{ _assetsDir }}/black-hand" />

				<bp-picture responsive skipPhone src="{{ _assetsDir }}/white-hand-white-card" />

				<bp-picture responsive skipPhone src="{{ _assetsDir }}/white-hand-red-card" />

				<bp-picture responsive skipPhone src="{{ _assetsDir }}/logo" />
			</div>

			<div class="main-block">
				<h4>Just a few more details<br />to get you started</h4>

				<bp-get-started-form-flow cdkFocusInitial class="mb-4" />

				<div class="join-business mb-4">Join global businesses and make your payments easy</div>
			</div>
		</div>
	</ng-template>
</bp-modal>
