import { distinctUntilChanged, filter, map } from 'rxjs';
import confetti, { CreateTypes } from 'canvas-confetti';

import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';

import { fireConfetti, PlayWhenPartiallyInViewportAnimationStrategy } from '@bp/promo/shared/core';

@Component({
	selector: 'bp-build-payment-flows',
	templateUrl: './build-payment-flows.component.html',
	styleUrls: [ './build-payment-flows.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildPaymentFlowsComponent extends Destroyable implements AfterViewInit {

	@ViewChild('confettiCanvas') canvas!: ElementRef<HTMLCanvasElement>;

	protected readonly _animationStrategy = new PlayWhenPartiallyInViewportAnimationStrategy(0.9);

	private __customConfetti!: CreateTypes;

	constructor() {
		super();

		this.__onAnimationReachEndLaunchConfetti();
	}

	ngAfterViewInit(): void {
		this.__customConfetti = confetti.create(this.canvas.nativeElement, { useWorker: true, resize: true });
	}

	private __onAnimationReachEndLaunchConfetti(): void {
		const cashierAnimationTransactionApprovedFrame = 180;

		this._animationStrategy.onFrameChange$
			.pipe(
				map(({ currentTime: currentFrame }) => currentFrame > cashierAnimationTransactionApprovedFrame),
				distinctUntilChanged(),
				filter(Boolean),
				takeUntilDestroyed(this),
			)
			.subscribe(() => void fireConfetti(this.__customConfetti));
	}

}
