import type { IAppEnvironmentConfig } from '@bp/shared/typings';

export const environment: Partial<IAppEnvironmentConfig> = {
	api: 'https://api.bridgerpay.com/api',
	apiVersion: 'v2',
	sentry: 'https://4de650d16bb14fbfaa2c7a9fe49eb4c0@o205478.ingest.sentry.io/5456168',
	signupOrigin: 'https://signup.bridgerpay.com',
	intercom: 'ff289wc0',
	// logrocket: 'fpkaz5/prod-promo-website', // too many sessions
	googleTagAnalyticsId: 'G-97LS2J27FZ',
	pardot: 994_042,
	cloudflareTurnstileSiteKey: '0x4AAAAAAAI7CO6n4cjEITHo',
};
