import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

import { LetDirective } from '@ngrx/component';

import { SharedFeaturesCarouselModule } from '@bp/frontend/features/carousel';
import { SharedFeaturesMediaModule } from '@bp/frontend/features/media';
import { SharedFeaturesAnalyticsModule } from '@bp/frontend/features/analytics';

import { SharedModule, ShowRegisterPspSurveyDirective } from '@bp/promo/shared/core';
import { CompaniesTilesComponent } from '@bp/promo/shared/components/companies-tiles';

import { HomeRoutingModule } from './home-routing.module';
import {
	AboveFoldComponent, SupportComponent, BuildPaymentFlowsComponent, CompaniesComponent,
	ContactsWithConcernedPartiesComponent, HomePageComponent, PaymentPlatformToFitComponent,
	PluginPaymentsComponent, TestimonialsComponent, NextGenOperationsComponent, AboveFoldSecureComponent,
	AboveFoldVideoComponent, CodelessConnectionsComponent
} from './pages';
import { ForDevsPricingComponent } from './pages/home-page/components/for-devs-pricing';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		MatExpansionModule,
		LetDirective,

		ShowRegisterPspSurveyDirective,

		SharedFeaturesCarouselModule,

		HomeRoutingModule,
		SharedFeaturesMediaModule,
		SharedFeaturesAnalyticsModule,
		CompaniesTilesComponent,
	],
	declarations: [
		HomePageComponent,
		AboveFoldComponent,
		CompaniesComponent,
		ContactsWithConcernedPartiesComponent,
		PaymentPlatformToFitComponent,
		PluginPaymentsComponent,
		BuildPaymentFlowsComponent,
		SupportComponent,
		NextGenOperationsComponent,
		TestimonialsComponent,
		ForDevsPricingComponent,
		AboveFoldSecureComponent,
		AboveFoldVideoComponent,
		CodelessConnectionsComponent,
	],
})
export class HomeModule {
}
