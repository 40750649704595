import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MediaService } from '@bp/frontend/features/media';

@Component({
	selector: 'bp-above-fold-secure',
	templateUrl: './above-fold-secure.component.html',
	styleUrls: [ './above-fold-secure.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboveFoldSecureComponent {

	@Input() assetsDir!: string;

	isGreaterThanTablet$ = this._media.greaterThan$('Tablet');

	constructor(
		private readonly _media: MediaService,
	) {
	}

}
