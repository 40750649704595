import { RequiredDTO, Mapper } from '@bp/shared/models/metadata';

import { WriteEnvironmentFirebaseDocumentApiRequest } from '@bp/frontend/features/firebase/models';

import { CrmLead } from './crm-lead';

export class WriteCrmLeadApiRequest extends WriteEnvironmentFirebaseDocumentApiRequest {

	static {
		this._initClassMetadata();
	}

	@Mapper(CrmLead)
	data!: CrmLead;

	constructor(dto: RequiredDTO<WriteCrmLeadApiRequest>) {
		super(dto);
	}

}
