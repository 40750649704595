<bp-svg-shared-icons-definitions />

<bp-svg-icons-definitions />

<bp-modal-outlet>
	<router-outlet name="modal" />
</bp-modal-outlet>

<div class="container">
	<bp-header *ngIf="(_isLanding$ | async) === false" />

	<content
		class="page-outlet-container"
		[@fadeRouteHitAnimations]="_shouldAnimateOutlet"
		(@fadeRouteHitAnimations.done)="_shouldAnimateOutlet = false; _routeAnimationObserver.done()"
	>
		<router-outlet #outlet (activate)="onActivate()" />
	</content>

	<bp-footer *ngIf="(_isLanding$ | async) === false" />
</div>
