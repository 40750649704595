import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import { HomePageComponent } from './pages';

const routes: Routes = [
	{
		path: '',
		component: HomePageComponent,
	},
];

@NgModule({
	imports: [ RouterModule.forChild(routes) ],
	exports: [ RouterModule ],
})
export class HomeRoutingModule { }
