import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, RouteReuseStrategy } from '@angular/router';

export class ConfigurableRouteReuseStrategy extends BaseRouteReuseStrategy implements RouteReuseStrategy {

	override shouldReuseRoute(futureSnapshot: ActivatedRouteSnapshot, currentSnapshot: ActivatedRouteSnapshot): boolean {
		return currentSnapshot.data['doNotReuseComponent']
			? false
			: super.shouldReuseRoute(futureSnapshot, currentSnapshot);
	}

}
