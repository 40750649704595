<ng-container *ngIf="dropdownMode; else inlineModeTpl">
	<button
		#submenuTrigger="matMenuTrigger"
		mat-button
		[matMenuTriggerFor]="submenu"
		(menuOpened)="opened = true"
		(menuClosed)="opened = false"
	>
		<ng-container *ngTemplateOutlet="btnContentTpl" />
	</button>

	<mat-menu #submenu="matMenu" backdropClass="{{ popupClassPrefix }}-submenu-backdrop">
		<ng-container *ngTemplateOutlet="submenuContentTpl" />
	</mat-menu>
</ng-container>

<ng-template #inlineModeTpl>
	<button mat-button (click)="opened = !opened">
		<ng-container *ngTemplateOutlet="btnContentTpl" />
	</button>

	<div *ngIf="opened" @slide>
		<ng-container *ngTemplateOutlet="submenuContentTpl" />
	</div>
</ng-template>

<ng-template #btnContentTpl>
	<span class="v-align-baseline">{{ itemName }}</span>

	<bp-svg-icon name="arrow-drop-down" class="bp-icon-md" [class.opened]="opened" />
</ng-template>

<ng-template #submenuContentTpl>
	<div class="{{ popupClassPrefix }}-submenu-content submenu-content">
		<ng-content />
	</div>
</ng-template>
