import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'bp-above-fold-video',
	templateUrl: './above-fold-video.component.html',
	styleUrls: [ './above-fold-video.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboveFoldVideoComponent {

	@Input() assetsDir!: string;

}
