import { animate, query, sequence, style, transition, trigger } from '@angular/animations';

export const ROUTE_ANIMATED_CLASS = 'route-animated';

const optional = {
	optional: true,
};

const enterAnimation = '0.35s ease-in-out';
const leaveAnimation = '0.2s ease-in-out';

export const FADE_ROUTE_HIT_ANIMATIONS = trigger('fadeRouteHitAnimations', [
	transition(getTrue, [
		query(`.${ ROUTE_ANIMATED_CLASS }:enter`, style({ opacity: 0, position: 'fixed' }), optional),

		sequence([
			query(
				`.${ ROUTE_ANIMATED_CLASS }:leave`,
				[
					style({ opacity: 1 }),
					animate(
						leaveAnimation,
						style({ opacity: 0 }),
					),
					style({ position: 'fixed' }),
				],
				optional,
			),
			query(
				`.${ ROUTE_ANIMATED_CLASS }:enter`,
				[
					style({
						opacity: 0,
						position: 'static',
						display: 'block',
					}),
					animate(
						enterAnimation,
						style({ opacity: 1 }),
					),
				],
				optional,
			),
		]),
	]),
]);

export function getTrue() {
	return true;
}

// TODO a workaround waiting for https://github.com/angular/material2/issues/8057 being merged
export const INSTANT_HIDE_ON_VOID = trigger(
	'instantHideOnVoid',
	[ transition('* => void', [ animate('0ms', style({ display: 'none' })) ]) ],
);
