import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'bp-new-label',
	template: 'New!',
	styleUrls: [ './new-label.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NewLabelComponent {
}
