import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'bp-company-submenu',
	templateUrl: './company-submenu.component.html',
	styleUrls: [ './company-submenu.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanySubmenuComponent {

	@Input() dropdownMode!: boolean | '';

	assetsDir = '/assets/company-submenu';

}
