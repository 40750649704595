import { from, Observable, of, switchMap } from 'rxjs';

import { Injectable, inject } from '@angular/core';

import { IntercomContactDTO, IntercomContact, INTERCOM_CONTACTS_COLLECTION_PATH } from '@bp/frontend/domains/crm/leads/models';
import { FirebaseService } from '@bp/frontend/features/firebase';
import { EnvironmentService } from '@bp/frontend/services/environment';
import { IntercomService } from '@bp/frontend/services/intercom';

@Injectable({
	providedIn: 'root',
})
export class IntercomContactApiService {

	private readonly __environment = inject(EnvironmentService);

	private readonly __firebaseService = inject(FirebaseService);

	private readonly __intercomService = inject(IntercomService);

	readonly collectionPath = `bp/${ this.__environment.name }/${ INTERCOM_CONTACTS_COLLECTION_PATH }`;

	readonly factory = (dto: IntercomContactDTO): IntercomContact => new IntercomContact(dto);

	listenToRemoteChanges(): Observable<IntercomContact | null> {
		return from(this.__intercomService.getVisitorId())
			.pipe(switchMap(visitorId => visitorId
				? this.__listenToRemoteChanges(visitorId)
				: of(null)));
	}

	private __listenToRemoteChanges(id: string): Observable<IntercomContact | null> {
		return this.__firebaseService.listenToDocumentChanges(
			`${ this.collectionPath }/${ id }`,
			this.factory,
		);
	}
}
