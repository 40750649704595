import { ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { BypassAuthorizationHeaderCheckInterceptorService } from '@bp/frontend/services/http';
import { SharedServicesModule as CommonSharedServicesModule } from '@bp/frontend/services/core';

@NgModule()
export class SharedServicesModule {

	static forRoot(): ModuleWithProviders<SharedServicesModule> {
		return {
			ngModule: SharedServicesModule,
			providers: [
				{
					provide: HTTP_INTERCEPTORS,
					useClass: BypassAuthorizationHeaderCheckInterceptorService,
					multi: true,
				},
				...CommonSharedServicesModule.forRoot().providers!,
			],
		};
	}

}
