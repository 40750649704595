import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';

import { attrBoolValue } from '@bp/shared/utilities/core';

import { SLIDE } from '@bp/frontend/animations';
import { MediaService } from '@bp/frontend/features/media';
import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { subscribeOutsideNgZone } from '@bp/frontend/rxjs';

@Component({
	selector: 'bp-submenu-item',
	templateUrl: './submenu-item.component.html',
	styleUrls: [ './submenu-item.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE ],
})
export class SubmenuItemComponent extends Destroyable implements OnInit {

	@Input() itemName!: string;

	@Input() popupClassPrefix!: string;

	@Input() dropdownMode!: boolean | '';

	@ViewChild(MatMenuTrigger) submenuTrigger?: MatMenuTrigger;

	opened = false;

	constructor(private readonly _media: MediaService) {
		super();

		this._closeSubmenuDropdownOnMobiles();

		this._closeSubmenuDropdownOnScroll();
	}

	ngOnInit(): void {
		this.dropdownMode = attrBoolValue(this.dropdownMode);
	}

	private _closeSubmenuDropdownOnMobiles(): void {
		this._media.lessThan$('Laptop')
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => this.submenuTrigger?.closeMenu());
	}

	private _closeSubmenuDropdownOnScroll(): void {
		fromEvent(window, 'scroll')
			.pipe(
				subscribeOutsideNgZone(),
				filter(() => !!this.submenuTrigger?.menuOpened),
				takeUntilDestroyed(this),
			)
			.subscribe(() => this.submenuTrigger?.closeMenu());
	}

}
