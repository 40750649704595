import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'bp-codeless-connections',
	templateUrl: './codeless-connections.component.html',
	styleUrls: [ './codeless-connections.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CodelessConnectionsComponent {}
