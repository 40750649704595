import { QuicklinkModule } from 'ngx-quicklink';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';
import { SharedFeaturesModalModule } from '@bp/frontend/features/modal';
import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';
import { SharedFeaturesAnalyticsModule } from '@bp/frontend/features/analytics';
import { AppVersionComponent } from '@bp/frontend/components/app-version';
import { DeveloperOverlayPanelComponent } from '@bp/frontend/components/developer-overlay-panel';

import { SharedModule } from '@bp/promo/shared/core';

import {
	CompanySubmenuComponent, FooterComponent, HeaderComponent, ModalMenuComponent,
	NewLabelComponent, RootComponent, SubmenuItemComponent, SvgIconsDefinitionsComponent, WeAreHiringBadgeComponent
} from './components';

export const COMPONENTS = [
	SvgIconsDefinitionsComponent,
	CompanySubmenuComponent,
	ModalMenuComponent,
	RootComponent,
	HeaderComponent,
	FooterComponent,
	WeAreHiringBadgeComponent,
	SubmenuItemComponent,
	NewLabelComponent,
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		MatIconModule,
		MatButtonModule,
		MatMenuModule,
		MatToolbarModule,
		MatDialogModule,
		QuicklinkModule,

		AppVersionComponent,
		DeveloperOverlayPanelComponent,

		SharedModule,
		SharedDirectivesCoreModule,
		SharedComponentsCoreModule,
		SharedFeaturesSvgIconsModule,
		SharedFeaturesModalModule,
		SharedFeaturesAnalyticsModule,
		SharedModule,
	],
	declarations: COMPONENTS,
	exports: COMPONENTS,
})
export class CoreModule { }
