import type { AnimationItem } from 'lottie-web-light';

import type { ILottieAnimationStrategy } from './lottie-animation-strategy';
import { BaseAnimationStrategy } from './base-animation-strategy';

export class ManualAnimationStrategy extends BaseAnimationStrategy implements ILottieAnimationStrategy {

	private _animationItem?: AnimationItem;

	override apply($host: HTMLElement, animationItem: AnimationItem): void {
		super.apply($host, animationItem);

		this._animationItem = animationItem;
	}

	play(): void {
		this._animationItem?.play();
	}

	stop(): void {
		this._animationItem?.stop();
	}

	resetAndStop(): void {
		this._animationItem?.goToAndStop(0);
	}

}
